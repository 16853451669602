@import "@/assets/style/_index.scss";

















































































































































































































































































































































































































































































@mixin changeBg {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: $padding-small;
    right: $padding-small;
    bottom: $padding-small;
    left: $padding-small;
    border: 3px dotted #fff;
    opacity: 0.5;
  }
  &:after {
    content: '更改';
    position: absolute;
    bottom: $padding;
    right: $padding-big;
    color: #fff;
  }
}
.store-banner {
  > .banner {
    position: relative;
    width: 100%;
    > .bg {
      width: 100%;
      padding-top: 30%;
      background: $gray2 center no-repeat;
      background-size: cover;
      @include changeBg;
    }
    > .customer-service-image {
      position: absolute;
      z-index: $z-index-2;
      right: $padding-small;
      top: $padding-small;
    }
  }

  .store-name {
    > input {
      outline: none;
      height: 100%;
      width: 100%;
      background-color: transparent;
    }
  }
  &.type1 {
    background-color: #fff;
    padding-bottom: $padding-big;
    > .inner {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding: 0 $padding-big;
      //transform: translateY(-50%);
      margin-top: -0.8rem;
      pointer-events: none;
      > .logo {
        flex: 0 0 auto;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: $padding-small;
        margin-right: $margin;
        background: $gray3 center no-repeat;
        background-size: cover;
        pointer-events: auto;
        @include changeBg;
      }
      > .info {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        > .store-name {
          font-size: 120%;
          font-weight: bold;
          line-height: 0.6rem;
          pointer-events: auto;
          @include text-line(1);
        }
        > .customer-service {
          line-height: 0.6rem;
          @include text-line(1);
        }
      }
    }
  }
  &.type2 {
    background-color: #fff;
    padding-bottom: $padding-big;
    > .banner {
      position: relative;
      > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 $padding-big;
        //transform: translateY(-50%);
        > .logo {
          flex: 0 0 auto;
          width: 1.6rem;
          height: 1.6rem;
          border-radius: $padding-small;
          margin-right: $margin;
          background: $gray3 center no-repeat;
          background-size: cover;
          @include changeBg;
        }
        > .info {
          flex: 1 1 1%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          > .store-name {
            font-size: 120%;
            font-weight: bold;
            line-height: 0.6rem;
            @include text-line(1);
            background-color: rgba(255, 255, 255, 0.9);
            display: inline-block;
            border-radius: 100vw;
            padding: 0 $padding;
          }
          > .customer-service {
            line-height: 0.6rem;
            @include text-line(1);
          }
        }
      }
    }
  }
  &.type3 {
    background-color: #fff;
    padding-bottom: $padding-big;
    > .banner {
      position: relative;
      > .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0 $padding-big;
        //transform: translateY(-50%);
        > .logo {
          flex: 0 0 auto;
          width: 1.2rem;
          height: 1.2rem;
          border-radius: $padding-small;
          background: $gray3 center no-repeat;
          background-size: cover;
          @include changeBg;
        }
        > .info {
          flex: 0 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          > .store-name {
            font-size: 110%;
            font-weight: bold;
            line-height: 0.6rem;
            @include text-line(1);
            background-color: rgba(255, 255, 255, 0.9);
            display: inline-block;
            border-radius: 100vw;
            padding: 0 $padding;
          }
          > .customer-service {
            line-height: 0.6rem;
            @include text-line(1);
          }
        }
      }
    }
  }
}
.goods-list-skeleton {
  &.type1 {
    > .item {
      + .item {
        margin-top: $margin;
      }
      > .inner {
        padding: $padding;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        background-color: #fff;
        > .img-obj {
          flex: 0 0 auto;
          width: 2rem;
          margin-right: $margin-small;
          > .inner {
            width: 100%;
            padding-top: 100%;
            background-color: $gray2;
          }
        }
        > .inner {
          flex: 1 1 1%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          > .price_,
          > .desc,
          > .label {
            background-color: $gray2;
            margin-top: $margin-small;
          }
          > .label {
            margin-top: 0;
          }
          > .desc {
            width: 80%;
          }
          > .price_ {
            width: 50%;
          }
        }
      }
    }
  }
  &.type2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding: $padding-small;
    > .item {
      width: 50%;
      padding: $padding-small;
      + .item {
        margin-top: 0;
      }
      > .inner {
        padding: $padding;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        background-color: #fff;
        > .img-obj {
          flex: 0 0 auto;
          width: 100%;
          > .inner {
            width: 100%;
            padding-top: 100%;
            background-color: $gray2;
          }
        }
        > .inner {
          flex: 1 1 1%;
          > .price_,
          > .desc,
          > .label {
            background-color: $gray2;
            margin-top: $margin-small;
          }
          > .desc {
            width: 80%;
          }
          > .price_ {
            width: 50%;
          }
        }
      }
    }
  }
}
.cat-list-skeleton {
  &.type1 {
    > .cat-list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch;
      padding: $padding;
      background-color: #fff;

      > .item {
        width: 2rem;
        background-color: $gray2;
        + .item {
          margin-left: $margin;
        }
      }
    }
  }
  &.type2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    > .cat-list {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      padding: $padding;
      background-color: #fff;

      > .item {
        width: 1.2rem;
        background-color: $gray2;
        + .item {
          margin-top: $margin;
        }
      }
    }
    > .goods-list-skeleton {
      flex: 1 1 1%;
    }
  }
}
